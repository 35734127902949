<template>

  <b-input-group>

    <b-input-group-prepend>
      <b-button v-b-modal="'modal-folders'">
        <img height="20" src="../../public/folder.svg"/>
      </b-button>
    </b-input-group-prepend>

    <b-form-input v-model="folder.title" :placeholder="t('all_folders')" readonly></b-form-input>

    <b-input-group-append>
      <b-button @click="reset()"><img height="10" src="cross.svg"/></b-button>
    </b-input-group-append>

    <b-modal id="modal-folders" :title="t('Folders')" size="lg" header-bg-variant="dark" header-text-variant="light" scrollable @ok="validate()">
      <FoldersList @select="select($event)"/>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ t('Select') }}</b-button>
        <b-button size="sm" variant="link" @click="cancel()">{{ t('Cancel') }}</b-button>
      </template>
    </b-modal>

  </b-input-group>

</template>


<script>

import FoldersList from '@/components/FoldersList.vue'

export default {
  components: {
    FoldersList
  },
  props: ['initialId'],
  data() {
    return {
      id: (this.initialId) ? this.initialId : undefined,
      selectedId: undefined,
    }
  },
  computed: {
    folder: function() {
      return (this.id) ? this.$store.getters.getFolder(this.id) : {}
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    select: function(id) {
      this.selectedId = id
    },
    reset:function() {
      this.selectedId = undefined
      this.validate()
    },
    validate: function() {
      this.id = this.selectedId
      this.$emit('select', this.id)
    },
  },
}

</script>



<style>


</style>
