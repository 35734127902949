<template>

<b-container>

  <b-tabs>

    <b-tab :title="t('select')" class="my-3" active>
      <Tree v-for="f in folders" :item="f" :key="f.id" @select="select($event)" @move="move($event)"/>
    </b-tab>

    <b-tab :title="t('manage')+' '+ this.selected.title " v-if="this.selected"  class="my-3">
      <b-form @submit="rename">
        <b-form-group id="input-group-title" :label="t('title')" label-for="input-title">
          <b-form-input id="input-title" v-model="selected.title" required></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="outline-success" size="sm" >{{ t('rename') }}</b-button>
      </b-form>

      <b-form @submit="createSubFolder">
        <b-form-group id="input-group-title" :label="t('new_sub_folder')" label-for="input-title" class="mt-5">
          <b-form-input id="input-title" :placeholder="t('title')" v-model="newSubFolderTitle" size="sm" required></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="outline-success" size="sm">{{ t('create') }}</b-button>
      </b-form>

      <label class="d-block mt-5">{{ t('other') }}</label>
      <b-button-group>
        <b-button @click="unparent" id="button-unparent" variant="outline-success">{{ t('unparent') }}</b-button>
        <b-button @click="destroy" variant="outline-danger">{{ t('delete') }}</b-button>
      </b-button-group>

    </b-tab>

    <b-tab :title="t('new_folder')" class="my-3">
      <b-form @submit="createFolder">
        <b-form-input id="input-newfolder" v-model="newFolderTitle" :placeholder="t('title')" size="sm" required></b-form-input>
        <b-button type="submit" variant="outline-success" class="my-3" >{{ t('create') }}</b-button>
      </b-form>
    </b-tab>

  </b-tabs>

</b-container>

</template>

<script>

import Tree from '@/components/Tree.vue'

export default {
  components: {
    Tree,
  },
  data () {
    return {
      selected: undefined,
      newFolderTitle: '',
      newSubFolderTitle: '',
    }
  },
  computed: {
    folders: function () {
      return this.$store.state.folders
    },
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    select: function(folder) {
      this.selected = folder
      this.$emit('select', this.selected.id)
    },
    move: function(data) {
      let folder = this.$store.getters.getFolder(data.folder_id)
      folder.parent_id = data.parent_id
      this.$http.patch('/folders/'+ folder.id, folder).then(() => {
        this.$store.dispatch('fetchFolders')
        this.$store.dispatch('updateNotif', this.t('folder_updated'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    createFolder: function(evt) {
      evt.preventDefault()
      let folder = {
        title: this.newFolderTitle,
        parent_id: 0
      }
      this.$http.post('/folders', folder).then(() => {
        this.$store.dispatch('fetchFolders')
        this.$store.dispatch('updateNotif', this.t('folder_created'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    createSubFolder: function(evt) {
      evt.preventDefault()
      let folder = {
        title: this.newSubFolderTitle,
        parent_id: this.selected.id
      }
      this.$http.post('/folders', folder).then(() => {
        this.$store.dispatch('fetchFolders')
        this.$store.dispatch('updateNotif', this.t('folder_created'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    rename: function(evt) {
      evt.preventDefault()
      this.$http.patch('/folders/'+ this.selected.id, this.selected).then(() => {
        this.$store.dispatch('fetchFolders')
        this.$store.dispatch('updateNotif', this.t('folder_updated'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    unparent: function(evt) {
      evt.preventDefault()
      let folder = this.selected
      folder.parent_id = 0
      this.$http.patch('/folders/'+ this.selected.id, folder).then(() => {
        this.$store.dispatch('fetchFolders')
        this.$store.dispatch('updateNotif', this.t('folder_updated'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    destroy: function() {
      this.$http.delete('/folders/'+ this.selected.id).then(() => {
        this.$store.dispatch('fetchFolders')
        this.$store.dispatch('updateNotif', this.t('folder_deleted'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
  }
}
</script>

<style scoped lang="scss">

</style>
