<template>

  <b-container v-if="bookmark" class="vertical-space">

    <b-row class="mb-5">
      <b-col cols="4" lg="2">
        <img :src="thumb" />
      </b-col>
      <b-col>
        <h2>{{ bookmark.title }}</h2>
        <p>{{ t('created_at') }} {{ bookmark.created_at|date }}</p>
        <p v-show="bookmark.visited_at">{{ t('visited_at') }} {{ bookmark.visited_at|date }}</p>
        <p>{{ t('score') }} {{ bookmark.score }}</p>
      </b-col>

    </b-row>

    <b-button-group class="my-5 d-none d-sm-flex">
      <b-button variant="success" @click="onSubmit">{{ t('save') }}</b-button>
      <b-button @click="close">{{ t('close') }}</b-button>
      <b-button @click="visit">{{ t('open') }}</b-button>
      <b-button variant="danger" @click="destroy">{{ t('delete') }}</b-button>
    </b-button-group>

    <b-button-group vertical class="my-5 d-flex d-sm-none">
      <b-button variant="success" @click="onSubmit">{{ t('save') }}</b-button>
      <b-button @click="close">{{ t('close') }}</b-button>
      <b-button @click="visit">{{ t('open') }}</b-button>
      <b-button variant="danger" @click="destroy">{{ t('delete') }}</b-button>
    </b-button-group>

    <b-tabs content-class="my-3">

      <b-tab :title="t('general')">

        <b-form @submit="onSubmit">
          <b-form-group id="input-group-title" :label="t('title')" label-for="input-title">
            <b-form-input id="input-title" v-model="bookmark.title" required></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-url" :label="t('url')" label-for="input-url">
            <b-form-input id="input-url" v-model="bookmark.url" type="url" required></b-form-input>
          </b-form-group>
          <b-form-group id="checkbox-group-star" :label="t('be_a_star')" label-for="checkbox-star">
            <b-form-checkbox id="checkbox-star" v-model="bookmark.star" value="1" unchecked-value="0" name="checkbox-star" size="lg" switch></b-form-checkbox>
          </b-form-group>

          <b-form-group id="input-group-folder" :label="t('folder')" label-for="input-folder">
            <InputFolder @select="changeFolder($event)" :initialId="bookmark.folder_id" />
          </b-form-group>

        </b-form>

      </b-tab>


      <b-tab :title="t('thumbnail')">

        <b-form-group id="input-group-thumbfile" :label="t('thumbfile_upload')" label-for="input-thumbfile">
          <b-form-file v-model="thumbfile" accept="image/jpeg, image/png" placeholder="" :browse-text="t('Browse')"></b-form-file>
        </b-form-group>

        <b-form-group id="input-group-thumbweb" :label="t('thumbweb_url')" label-for="input-thumbweb">
          <b-form-input id="input-thumbweb" v-model="bookmark.thumbweb" type="url" placeholder="http://"></b-form-input>
        </b-form-group>


        <label>{{ t('thumbweb_select') }}</label>
        <p class="loader" v-show="!thumbs">
          <img src="832.svg"/>
          <span>{{ t('search_online_thumbnails') }}</span>
        </p>

        <div name="thumbnails" class="thumbs">
          <span class="item" :class="{ 'current': thumbweb === undefined }"
            @click="setThumbWeb(undefined)">
            <img :src="thumb" :alt="t('current_thumb')" :title="t('current_thumb')"/>
          </span>
          <span v-for="thumb in thumbs" v-bind:key="thumb"
            class="item" :class="{ 'current': thumbweb == thumb }"
            @click="setThumbWeb(thumb)">
            <img :src="thumb" :alt="thumb" :title="thumb"/>
          </span>
        </div>

      </b-tab>


    </b-tabs>

  </b-container>


</template>

<script>

import InputFolder from '@/components/InputFolder.vue'

export default {
  components: {
    InputFolder,
  },
  data: function() {
    return {
      bookmark: undefined,
      thumbs: undefined,
      thumbweb: undefined,
      thumbfile: null,
    }
  },
  computed: {
    thumb: function() {
      return this.bookmark.thumb + '?'+ Math.random()
    },
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    changeFolder: function($event) {
      this.bookmark.folder_id = $event
    },
    fetchBookmark: function(id) {
      this.$http.get('/bookmarks/'+ id).then(response => {
        this.bookmark = response.data
        this.fetchThumbs(this.bookmark.url)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
        this.$router.push('/')
      });
    },
    fetchThumbs: function(url) {
      this.$http.post('/thumbs', {url: url}).then(response => {
        this.thumbs = response.data
      }).catch(() => {
        return []
      });
    },
    setThumbWeb: function(url) {
      this.thumbweb = url
    },
    uploadThumb: function() {
      let formData = new FormData();
      formData.append('thumbfile', this.thumbfile);
      this.$http.post( '/bookmarks/'+ this.bookmark.id +'/downloadThumbFromFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    onSubmit: function(evt) {
      evt.preventDefault()
      // send thumbfile if needed
      if (this.thumbfile)
        this.uploadThumb()
      // set thumbweb if needed
      if (this.thumbweb)
        this.bookmark.thumbweb = this.thumbweb
      // patch the bookmark
      this.$http.patch('/bookmarks/'+ this.bookmark.id, this.bookmark).then(response => {
        this.bookmark = response.data
        this.bookmark.thumb += '?'+ Math.random()
        this.$store.commit('replaceBookmark', this.bookmark)
        this.$store.dispatch('updateNotif', this.t('bookmark_updated'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
    },
    visit: function() {
      //this.$store.dispatch('visitBookmark', this.bookmark.id)
      this.$http.get('/bookmarks/visit/'+ this.bookmark.id).then(response => {
        this.$store.commit('replaceBookmark', response.data)
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
      window.open(this.bookmark.url, '_blank')
    },
    close: function() {
      this.$router.go(-1)
    },
    destroy: function() {
      //this.$store.dispatch('deleteBookmark', this.bookmark.id)
      this.$http.delete('/bookmarks/'+ this.bookmark.id).then(() => {
        this.$store.commit('removeBookmark', this.bookmark.id)
        this.$store.dispatch('updateNotif', this.t('bookmark_deleted'))
      }).catch(error => {
        this.$store.dispatch('handleError', error.response)
      });
      this.close()
    },

  },
  created () {
    this.fetchBookmark(this.$route.params.id);
  }
}
</script>

<style scoped lang="scss">

div.thumbs {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  overflow: hidden;
  .item {
    flex: 0 0 auto;
    width: 170px;
    margin: 10px;
    padding: 10px;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
  }
  .current {
    background-color: #fff;
    border: 5px solid lightgreen;
  }
}

p.loader {
  img {
    height: 50px;
    vertical-align: middle;
    margin-right: 2em;
  }
}

@media only screen and (max-width: 800px) {

  div.thumbs {
    .item {
      width: 80px;
      padding: 0;
    }
  }

}
</style>
