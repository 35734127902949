<template>
  <div class="item">
    <div class="label" draggable @drop="drop($event, item)" @dragstart="drag($event, item)" @dragover="dragover($event)" @click="select">
      <span class="icon"></span>
      <span class="title" :class="{ visible: open }" >
        {{ item.title }}
      </span>
    </div>
    <div class="children" v-if="open">
      <tree v-for="c in item.children" :item="c" :key="c.id" @select="$emit('select', $event)"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "tree",
  props: ["item"],
  data () {
    return {
      open: false,
    }
  },
  methods: {
    t: function(s) {
      return this.$store.getters.t(s)
    },
    select: function() {
      this.open = !this.open
      this.$emit('select', this.item)
    },
    dragover: function(evt) {
      evt.preventDefault();
    },
    drag: function(evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData("itemID", item.id)
    },
    drop: function(evt, item) {
      let folder_id = evt.dataTransfer.getData("itemID")
      let parent_id = item.id
      if (folder_id != parent_id) {
        this.$emit('move', {
          'folder_id': folder_id,
          'parent_id': parent_id
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">

div.children {
  margin-left: 1em;
}

div.label {
  padding: .4em;
}
div.label:hover {
  background-color: rgba(0,0,0,.2);
  cursor:pointer;
}

span.icon {
  display: inline-block;
  margin-right: .3em;
  width: 16px;
  height: 16px;
  background: url('../../public/folder.svg') no-repeat center center;
  background-size: cover;
}


</style>
